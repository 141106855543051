<template>
  <b-col class="text-center">
    <h5 :style="`font-size: ${isMobile ? '30px' : '50px'}`" v-if="isShowMain">
      Paket Kami
    </h5>
    <h6>{{ title }}</h6>
    <CardPack :title="title" />
    <hr />
  </b-col>
</template>

<script>
import CardPack from "@/components/Packages/CardPack";
import { mapState } from "vuex";
export default {
  components: {
    CardPack,
  },
  data() {
    return {
      isShowMain: false,
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  props: {
    title: String,
  },
  mounted() {
    if (this.title == "Dedicated") {
      this.isShowMain = true;
    }
  },
};
</script>
