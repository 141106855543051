<template>
  <b-container fluid>
    <b-row>
      <b-col class="p-0 home">
        <!-- <Navbar /> -->
        <b-navbar
          style="background-color: #3b4bf9"
          toggleable="lg"
          class="px-1"
          fixed="top"
        >
          <b-navbar-brand href="#" class="p-2 text-center text-light">
            <img
              src="@/assets/mssLogo.png"
              width="40px"
              height="40px"
              class="d-inline-block align-top"
              alt="Kitten"
            />
            {{ isMobile ? "" : "Media Solusi Sukses" }}
            <div v-if="isMobile">
              <h6 class="text-light">Media Solusi Sukses</h6>
            </div>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item
                @click="scrollToElement({ behavior: 'smooth' }, 'home')"
                style="color: white !important"
                >Home</b-nav-item
              >
              <b-nav-item
                @click="scrollToElement({ behavior: 'smooth' }, 'aboutus')"
                >Tentang Kami</b-nav-item
              >
              <b-nav-item
                @click="scrollToElement({ behavior: 'smooth' }, 'area-kami')"
                >Area Kami</b-nav-item
              >
              <b-nav-item-dropdown text="Produk Kami" right>
                <b-nav-item
                  @click="
                    scrollToElement({ behavior: 'smooth' }, 'paketDedicated')
                  "
                >
                  Dedicated
                </b-nav-item>
                <!-- <b-nav-item
                  @click="scrollToElement({ behavior: 'smooth' }, 'paketHome')"
                >
                  Home
                </b-nav-item> -->
              </b-nav-item-dropdown>
              <b-nav-item
                @click="scrollToElement({ behavior: 'smooth' }, 'kontak')"
                >Kontak kami</b-nav-item
              >
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto" v-if="isMobile">
              <b-nav-item-dropdown text="Bahasa" left>
                <b-dropdown-item>Indonesia</b-dropdown-item>
                <b-dropdown-item>Inggris</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto" v-if="!isMobile">
            <b-nav-item-dropdown text="Bahasa" left>
              <b-dropdown-item>Indonesia</b-dropdown-item>
              <b-dropdown-item>Inggris</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-navbar>
      </b-col>
    </b-row>
    <b-row class="cover">
      <Cover />
    </b-row>
    <b-row class="mt-3">
      <b-col class="p-4 mt-3">
        <h5
          class="text-center"
          :style="`font-size: ${isMobile ? '20px' : '20px'}`"
        >
          Pengertian Internet Service Provider (ISP)
        </h5>
        <span
          >Apa itu ISP? Pengertian ISP (Internet Service Provider) atau
          Penyelenggara Jasa Internet (PIJI) adalah rekanan atau produsen yang
          memberikan jasa layanan akses internet atau media komunikasi dan
          informasi berbasis online. Internet Service Provider ini memiliki
          jaringan yang luas, baik secara domestik maupun internasional sehingga
          para penggunanya dapat terkoneksi dengan jaringan internet global.
          Sesuai pengertian ISP di atas, jaringan internet tersebut merupakan
          media transmisi yang mampu mengalirkan data-data dari satu tempat ke
          tempat lainnya. Media transmisi ini berupa kabel, modem dan jalurnya,
          radio atau VSAT</span
        >
      </b-col>
    </b-row>
    <b-row align-v="center" style="margin-top: 50px" class="aboutus">
      <Aboutus />
    </b-row>
    <b-row class="paketDedicated">
      <ContainerPack :title="'Dedicated'" />
    </b-row>
    <!-- <b-row class="paketHome">
      <ContainerPack :title="'Home'" />
    </b-row> -->
    <b-row>
      <b-col class="text-center bg-light">
        <h5
          class="text-primary area-kami"
          :style="`font-size: ${isMobile ? '30px' : '50px'};`"
        >
          Area Kami
        </h5>
        <img src="@/assets/mapJava.png" alt="" width="100%" />
        <span class="text-dark text-bold">
          <b>Kami sudah menjangkau ke seluruh area di pulau jawa.</b>
        </span>
      </b-col>
    </b-row>
    <b-row class="mt-4 p-4 bg-dark text-light kontak">
      <b-col md="3" sm="12" class="pt-4">
        <img
          src="@/assets/mssLogo.png"
          width="30px"
          class="d-inline-block align-top"
          alt="Kitten"
        />
        <span style="font-weight: bold">Media Solusi Sukses</span>
        <br />
        <span
          >PT. Media Solusi Sukses adalah sebuah perusahaan yang menyediakan
          Jasa Layanan Internet melalui jaringan Wifi</span
        >
      </b-col>
      <b-col class="pt-4" sm="12" md="3">
        <h6>Address</h6>
        <!-- <span
          >Perum. Bumi Karawang Residence, Blok G12 No. 9, Desa Cengkong,
          Kecamatan Purwasari, Kabupaten Karawang.
          <a href="https://goo.gl/maps/rmmtBd4fgdbu7hRm7"
            >(Klik disini)</a
          ></span
        > -->
        <span
          >Perum. Pesona Cengkong Asri, Blok A11/5, Desa Cengkong, Kec.
          Purwasari, Kab. Karawang. Kami bergerak di bidang Internet Service
          Provider (ISP)
          <a href="https://maps.app.goo.gl/8j2cCTiEa6j2Q8zh7"
            >(Klik disini)</a
          ></span
        >
      </b-col>
      <b-col class="pt-4" sm="12" md="3">
        <h6>Customer Services</h6>
        <span>
          HP: <br />
          <a
            :href="`https://wa.me/6285716765660?text=Hallo kak admin. ada yang mau saya tanyakan`"
            >085716765660 (Whatsapp)</a
          ></span
        ><br />
        <span> Telp: <br />(021)-39700444</span><br />
        <span> </span>
      </b-col>
      <b-col class="pt-4" sm="12" md="3">
        <h6>Social Media</h6>
        <span
          ><b-icon icon="inbox-fill"></b-icon> Email : <br />
          <a href="mailto:admin@mediasolusisukses.co.id" target="_blank"
            >admin@mediasolusisukses.co.id</a
          ></span
        ><br />
        <span
          ><b-icon icon="facebook"></b-icon> Facebook: <br />
          <a href="https://www.facebook.com/shafa.net.92">Shafa.Net</a></span
        ><br />
        <span
          >Instagram : <br />
          <a href="https://www.instagram.com/shafa_net/">shafa_net</a></span
        >
      </b-col>
    </b-row>
    <!-- <b-row class="mt-4 p-4 bg-dark text-light kontak">
      <b-col lg="4">
        <span>
          Sugeng:
          <a
            href="mailto:sugeng.priyanto@mediasolusisukses.co.id"
            target="_blank"
            >sugeng.priyanto@mediasolusisukses.co.id</a
          ></span
        ><br />
        <a
          :href="`https://wa.me/6282297560210?text=Hallo kak admin. ada yang mau saya tanyakan`"
          >(Whatsapp)</a
        >
      </b-col>
      <b-col lg="4">
        <span>
          Sugeng:
          <a
            href="mailto:sugeng.priyanto@mediasolusisukses.co.id"
            target="_blank"
            >sugeng.priyanto@mediasolusisukses.co.id</a
          ></span
        ><br />
        <a
          :href="`https://wa.me/6282297560210?text=Hallo kak admin. ada yang mau saya tanyakan`"
          >(Whatsapp)</a
        >
      </b-col>
      <b-col lg="4">
        <span>
          Sugeng:
          <a
            href="mailto:sugeng.priyanto@mediasolusisukses.co.id"
            target="_blank"
            >sugeng.priyanto@mediasolusisukses.co.id</a
          ></span
        ><br />
        <a
          :href="`https://wa.me/6282297560210?text=Hallo kak admin. ada yang mau saya tanyakan`"
          >(Whatsapp)</a
        >
      </b-col>
    </b-row> -->
  </b-container>
</template>

<script>
// import Navbar from "@/components/Navbar";
import Cover from "@/components/Cover.vue";
import Aboutus from "@/components/Aboutus.vue";
import ContainerPack from "@/components/Packages/ContainerPack";

import { mapState } from "vuex";

export default {
  name: "Homepage",
  components: {
    // Navbar,
    Cover,
    Aboutus,
    ContainerPack,
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  methods: {
    scrollToElement(options, className) {
      const el = this.$el.getElementsByClassName(className)[0];
      console.log(el);
      if (el) {
        el.scrollIntoView(options);
      }
    },
  },
};
</script>

<style>
.txt-50 {
  font-size: 50px;
}
a {
  text-decoration: none;
  color: teal;
}
h6 {
  font-weight: bold;
}
.navbar-nav .nav-item a {
  color: white !important;
}
.dropdown-menu {
  background-color: black;
  color: white;
}
.dropdown-menu li a:hover {
  color: teal !important;
}
</style>