<template>
  <b-col class="px-0">
    <div
      class="text-center"
      :style="`margin-top: ${isMobile ? '320px' : '480px'}`"
    >
      <h1
        :style="`font-size: ${
          isMobile ? '20px' : ''
        };text-transform: uppercase`"
      >
        Jelajahi Dunia dengan Shafa.Net
      </h1>
      <span>Nikmati Akses Internet Tanpa Batas Bersama Kami!</span>
    </div>
    <img
      src="@/assets/coverhome.png"
      width="100%"
      :height="`${isMobile ? '400px' : '600px'}`"
      style="position: absolute; top: 0px; z-index: -1"
    />
  </b-col>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "jumbotron",
  computed: {
    ...mapState(["isMobile"]),
  },
};
</script>