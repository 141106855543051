<template>
  <b-col>
    <b-row>
      <b-col class="p-20" md="6" sm="12" align-self="center">
        <h5 class="aboutus" :style="`font-size: ${isMobile ? '30px' : '50px'}`">
          About Us
        </h5>
        <span>PT. Media Solusi Sukses</span>
        <br />
        <br />
        <p>
          PT. Media Solusi Sukses adalah sebuah perusahaan yang menyediakan Jasa
          Layanan Internet melalui jaringan Wifi yang beralamat di Perum. Bumi
          Karawang Residence, Blok G12 No. 9, Desa Cengkong, Kecamatan
          Purwasari, Kabupaten Karawang.
          <br />
          Berdiri sejak tahun 2018
        </p>
        <a href="https://goo.gl/maps/rmmtBd4fgdbu7hRm7" target="_blank">
          <b-button variant="primary">Lihat Details</b-button>
        </a>
      </b-col>
      <b-col md="6" sm="12">
        <img src="@/assets/aboutus.jpeg" alt="" width="100%" />
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["isMobile"]),
  },
};
</script>
<style scoped>
.p-20 {
  padding: 50px;
}
</style>