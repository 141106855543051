<template>
  <b-row>
    <b-col class="mt-1" v-for="(item, i) in packages" :key="i">
      <b-card>
        <template #header>
          <h4 class="mb-0">{{ item.name }}</h4>
        </template>
        <b-card-body class="p-0">
          <img v-if="item.url" :src="item.url" width="100px" height="100px" />
          <b-card-sub-title class="mb-2">{{ item.bandwidth }}</b-card-sub-title>
          <b-card-text>
            <span>{{ item.price }}</span>
            <br />
            <a
              :href="`https://wa.me/6285716765660?text=${item.message}`"
              target="_blank"
            >
              <b-button variant="primary">Hubungi Kami</b-button>
            </a>
          </b-card-text>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      packagesDedicated: [
        {
          bandwidth: "100 Mbps",
          name: "Bronze",
          message:
            "Hallo Admin, Saya ingin menanyakan terkait paket Bronze (100 Mbps)",
          price: "Rp. 4.000.000",
          url: require(`@/assets/bronze.png`),
        },
        {
          bandwidth: "200 Mbps",
          name: "Silver",
          message:
            "Hallo Admin, Saya ingin menanyakan terkait paket Silver (200 Mbps)",
          price: "Rp. 8.000.000",
          url: require(`@/assets/silver.png`),
        },
        {
          bandwidth: "300 Mbps",
          name: "Gold",
          message:
            "Hallo Admin, Saya ingin menanyakan terkait paket Gold (300 Mbps)",
          price: "Rp. 11.000.000",
          url: require(`@/assets/gold.png`),
        },
        {
          bandwidth: "500 Mbps",
          name: "Platinum",
          message:
            "Hallo Admin, Saya ingin menanyakan terkait paket Platinum (500 Mbps)",
          price: "Rp. 19.000.000",
          url: require(`@/assets/platinum.png`),
        },
        {
          bandwidth: "1 Gbps",
          name: "Diamond",
          message:
            "Hallo Admin, Saya ingin menanyakan terkait paket Diamond (1 Gbps)",
          price: "Rp. 35.000.000",
          url: require(`@/assets/diamond.png`),
        },
      ],
      packagesHome: [
        {
          bandwidth: "10 Mbps",
          name: "Bronze",
          message:
            "Hallo Admin, Saya ingin menanyakan terkait paket Bronze (10 Mbps)",
        },
        {
          bandwidth: "20 Mbps",
          name: "Silver",
          message:
            "Hallo Admin, Saya ingin menanyakan terkait paket Silver (20 Mbps)",
        },
        {
          bandwidth: "30 Mbps",
          name: "Gold",
          message:
            "Hallo Admin, Saya ingin menanyakan terkait paket Gold (30 Mbps)",
        },
        {
          bandwidth: "40 Mbps",
          name: "Platinum",
          message:
            "Hallo Admin, Saya ingin menanyakan terkait paket Platinum (40 Mbps)",
        },
        {
          bandwidth: "50 Mbps",
          name: "Diamond",
          message:
            "Hallo Admin, Saya ingin menanyakan terkait paket Diamond (50 Mbps)",
        },
      ],
      packages: [],
    };
  },
  props: {
    title: String,
  },
  mounted() {
    if (this.title == "Dedicated") {
      this.packages = this.packagesDedicated;
    } else {
      this.packages = this.packagesHome;
    }
  },
};
</script>